<template>
  <div class="grid">
    <div class="col-12 lg:col-6 xl:col-3" v-if="showclientcoutn">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Clients</span>
            <div class="text-900 font-medium text-xl">{{ countClient }}</div>
          </div>
          <div class="
              flex
              align-items-center
              justify-content-center
              bg-blue-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Réclamations</span>
            <div class="text-900 font-medium text-xl">
              {{ countReclamation }}
            </div>
          </div>
          <div class="
              flex
              align-items-center
              justify-content-center
              bg-blue-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Intervention</span>
            <div class="text-900 font-medium text-xl">
              {{ countIntervention }}
            </div>
          </div>
          <div class="
              flex
              align-items-center
              justify-content-center
              bg-cyan-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-inbox text-cyan-500 text-xl"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Besoin en piece
            </span>
            <div class="text-900 font-medium text-xl">{{ besoinPiece }}</div>
          </div>
          <div class="
              flex
              align-items-center
              justify-content-center
              bg-purple-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-comment text-purple-500 text-xl"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <Chart type="bar" :data="basicData" :options="basicOptions" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      showclientcoutn: false,
      countIntervention: 0,
      countReclamation: 0,
      countClient: 0,
      besoinPiece: 0,
      basicOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      },
      basicData: {
        labels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        datasets: [
          {
            label: 'Intérventions',
            backgroundColor: '#42A5F5',
            data: []
          }
        ]
      },
    };
  },
  created() {
    if (localStorage.token == "" || localStorage.token == null) {
      this.$router.push("/login");
    } else {
      this.countDashboard();
    }
  },
  mounted() {
    if (localStorage.role_id != 3) {
      this.showclientcoutn = true;
    }
  },

  methods: {
    countDashboard() {
      axios
        .get("countDashboard")
        .then((response) => {
          this.countIntervention = response.data.countIntervention;
          this.countReclamation = response.data.countReclamation;
          this.countClient = response.data.countClient;
          this.besoinPiece = response.data.besoinPiece;
        })
        .catch(function (error) {
          if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            window.location.replace("/");
            // self.$router.push({ path: "/login" });
            // console.log(this.selectedCiasse);
          }
        });
      axios
        .get("statistiqueDash")
        .then((response) => {
          let tabl = [];
          response.data.statistiqueDash.forEach(element =>
            tabl.push(element.countReclamation)
          );
          this.basicData.datasets[0].data = tabl;
        })
        ;
    },
  },
};
</script>