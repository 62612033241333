<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img alt="Logo" :src="topbarImage()" />
      <span>MS INVEST</span>
    </router-link>
    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle"
    >
      <i class="pi pi-bars"></i>
    </button>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <li>
        <router-link
          to="/calendar"
          tag="button"
          class="p-link layout-topbar-button"
          ><i class="pi pi-calendar"></i> <span>Events</span></router-link
        >
      </li>
      <li>
        <button class="p-link layout-topbar-button" @click="logout">
          <i class="pi pi-sign-out"></i>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      localStorage.removeItem("permissionNames");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      this.permissionNames = [];
      this.user = {};
      this.token = "";
      this.$router.push("/login");
    },
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    onTopbarMenuToggle(event) {
      this.$emit("topbar-menu-toggle", event);
    },
    topbarImage() {
      return this.$appState.darkTheme ? "images/logo.jpeg" : "images/logo.jpeg";
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>